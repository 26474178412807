import React, { useEffect, useState } from "react";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Legend
} from 'recharts';
import moment from 'moment-timezone';

import Food from './Food'
import PhaseTable from "./PhaseTable";

const CurrentDay = () => {
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [highlightDay, setHighlightDay] = useState(null);

    // Handle date selection
    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    // Handle form submission to calculate the highlight day
    const handleSubmit = async () => {

        // If selectedDate is not set(falsy), the function exits immediately.
        if (!selectedDate) return;

        //calculate the daysDifference
        const userDate = moment.tz(selectedDate, 'YYYY-MM-DD', 'America/New_York');
        const currentDate = moment.tz('America/New_York');
        const daysDifference = currentDate.diff(userDate, 'days');

        if (daysDifference > 28) {
            alert("You are out of cycle");
            return;
        }

        //set highlightDay by conferring w/ backend
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chart/personal`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ selectedDate })
            });
            const result = await response.json();

            if (response.ok) {
                setHighlightDay({ day: result.day, phase: result.phase });
                // console.log('Highlight day set to:', result.day, 'Phase:', result.phase);
            }
        } catch (error) {
            console.error("Error fetching personal data:", error);
        }
    };

    // Fetch data 
    useEffect(() => {
        const fetchData = async () => {

            //this endpoint brings in entire collection
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chart`);
            const result = await response.json();
            if (response.ok) {
                const sortedData = result.sort((a, b) => a.day - b.day);
                setData(sortedData);
            }
        };
        fetchData();
    }, []);

    //Input date/////////////////////////
    const InputDate = (
        <div className="inputDate" style={{ color: '#1b3b6f', marginTop: '20px' }}>
            <p style={{
                fontSize: '14px',
                fontWeight: 600,
                marginRight: '10px',
                fontFamily: "'Roboto', sans-serif",
            }}>
                Enter the first day of your last period:</p>

            <input
                style={{
                    width: '110px',
                    height: '30px',
                    marginRight: '10px',
                    marginTop: '5px',
                    fontFamily: "'Roboto', sans-serif",
                    textAlign: 'center',
                    color: '#0353a4'
                }}
                type="date"
                value={selectedDate}
                onChange={handleDateChange} />

            <button onClick={handleSubmit}
                style={{
                    color: '#1b3b6f',
                    borderRadius: '5px',
                    padding: '10px 0px 10px 0px',
                    border: '1px solid transparent',
                    width: '70px'
                }}>
                Submit</button>
        </div>
    );

    //Legend/////////////////////////
    const renderCustomLegend = (props) => {
        const { payload } = props;

        // Use window width to adjust styles dynamically
        const isMobile = window.innerWidth <= 414;

        return (
            <ul
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyleType: 'none',
                    padding: '0',
                    paddingBottom: 2.5,
                    margin: 0,
                    justifyContent: 'center',
                    gap: isMobile ? '8px 10px' : '12px 20px',
                }}
            >
                {payload.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: isMobile ? '10px' : '10px',
                            fontFamily: "'Roboto', sans-serif",
                            color: '#444',

                        }}
                    >
                        {/* Custom icon with adjustable size */}
                        <div
                            style={{
                                width: isMobile ? 8 : 10, // Smaller icon on mobile
                                height: isMobile ? 8 : 10,
                                borderRadius: entry.type === 'circle' ? '50%' : 0,
                                backgroundColor: entry.color,
                                marginRight: isMobile ? 4 : 6, // Adjust spacing for mobile
                            }}
                        />
                        {entry.value} {/* Legend text */}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="bodyStacked">

            {/* CONDITIONAL RENDING •  If highlightDay is truthy then Food is rendered */}
            {highlightDay && <Food highlightDay={highlightDay} />}
            {highlightDay && <PhaseTable highlightDay={highlightDay} />}

            {/* INPUT DATE */}
            {!highlightDay && InputDate}


            {/* HORMONE CHART */}
            <div className="chartDiv">
                <ResponsiveContainer height={300} style={{ paddingTop: '5px' }} >

                    <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>

                        <XAxis dataKey="day" tick={{ fontSize: 10 }} interval={5} />
                        {/* <YAxis label={{ value: "Hormone Level", angle: -90, position: "insideLeft" }} /> */}

                        <Legend
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                            content={renderCustomLegend}

                        />

                        {/* LINE INDICATING WHERE YOU ARE */}
                        {highlightDay && (
                            <ReferenceLine
                                x={highlightDay.day}
                                stroke="#0353a4"
                                strokeWidth={5}
                                label={{ value: "You are here", position: "top", fill: "#0353a4", fontSize: 14, marginTop: '15px' }}
                            />
                        )}

                        {/* Line charts for each hormone */}
                        <Line type="monotone" dataKey="FSH" stroke="#947bd3" strokeWidth={2.25} dot={{ r: 0, fill: "#947bd3" }} />
                        <Line type="monotone" dataKey="estrogen" stroke="#FF00FF" strokeWidth={2.25} dot={{ r: 0, fill: "#FF00FF" }} />
                        <Line type="monotone" dataKey="LH" stroke="#07a0c3" strokeWidth={2.25} dot={{ r: 0, fill: "#07a0c3" }} />
                        <Line type="monotone" dataKey="progesterone" stroke="#ff6700" strokeWidth={2.25} dot={{ r: 0, fill: "#ff6700" }} />
                    </LineChart>



                </ResponsiveContainer>

            </div>
        </div>

    );


}
export default CurrentDay