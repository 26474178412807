import React, { useEffect, useState } from "react";
import "../components/styleSheets/sk.css"

const PhaseTable = ({ highlightDay }) => {
    const [data, setData] = useState([]);
    const [matchingData, setMatchingData] = useState([]);

    // Fetch data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chart`);
                const result = await response.json();
                if (response.ok) {
                    setData(result);
                } else {
                    console.error("Failed to fetch data:", result.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    // Filter matching data for the highlighted day
    useEffect(() => {
        if (highlightDay?.day && data.length) {
            const filtered = data.filter((item) => item.day === highlightDay.day);
            setMatchingData(filtered);
        }
    }, [highlightDay, data]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>

            <table className="chartDiv" style={{ border: 'none', borderRadius: '0', marginBottom: '10px', color: '#1b3b6f', borderCollapse: "collapse", textAlign: 'left' }} >

                <thead style={{ fontSize: '11px', backgroundColor: '#fff', }}>
                    <tr>
                        <th style={{ padding: "5px", border: "1px solid #1b3b6f" }}>Phase</th>
                        <th style={{ padding: "5px", border: "1px solid #1b3b6f" }}>Description</th>
                        <th style={{ padding: "5px", border: "1px solid #1b3b6f" }}>Energy</th>
                        <th style={{ padding: "5px", border: "1px solid #1b3b6f" }}>Recommended Exercise</th>
                    </tr>
                </thead>

                <tbody style={{ fontSize: '10px' }}>
                    {matchingData.length ? (
                        matchingData.map((row, index) => (
                            <tr key={index}>
                                <td style={{ padding: "7px", border: "1px solid #1b3b6f" }}>{row.phase}</td>
                                <td style={{ padding: "7px", border: "1px solid #1b3b6f" }}>{row.description}</td>
                                <td style={{ padding: "7px", border: "1px solid #1b3b6f" }}>{row.energy}</td>
                                <td style={{ padding: "7px", border: "1px solid #1b3b6f" }}>{row.exercise}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>

            </table>
        </div>
    );
};

export default PhaseTable;